import React, { useRef, useState, useEffect } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import TagManager from "react-gtm-module";
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-select'
import DOMPurify from 'dompurify';
import Checkbox from '../../../Common/Checkbox';
import checkedOption from '../../../../assets/image/option-selected.png'

const FeatureDevMicrositeEnquireForm = (props) => {

  const inputFieldRef = useRef(null)
  const successPageUrl = props.fields.SuccessPage ? props.fields.SuccessPage.value : '';
  const [formData, setFormData] = useState({
    fields: props.fields.Fields,
    userEmailAddress: "",
    complete: false,
    submitted: false,
    successMessage: null,
    successPage: props.fields.SuccessPage,
    postData: [],
    show: false,
    showField: null,
    verifiedRecaptcha: false,
    expiredRecaptcha: false,
    load: false,
    recaptchaLoaded: false,
  })

  // select custom style
  const customDropDownStyles = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: 'markpro',
      boxShadow: `0px 0px 0px ${state.isFocused ? '2px' : '1px'} ${state.isFocused ? '#303030' : '#969696'}`,
      outline: "none",
      border: "none",
      //border: state.isFocused? "solid 1px #303030" : "solid 1px #969696",
      // margin: state.isFocused? "0 -1px" : "0",
      // outline: state.isFocused? "solid 1px #303030" : 'none',
      minHeight: "56px",
      minWidth: "100%",
      overflow: "hidden",

      "&:hover": {
        // border: "solid 1px #5a6874",
        boxShadow: `0px 0px 0px 2px ${state.isFocused ? '#303030' : '#5a6874'}`,
        overflow: "hidden",
        //boxShadow: "none",
        // margin: "0 -1px"
      },

    }),
    valueContainer: (provided, state) => ({
      ...provided,
      "max-height": "54px",
      padding: "0",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      padding: "15px 0 0 15px",
      color: "#707070",
      margin: 0
    }),
    singleValue: () => ({
      fontFamily: 'markpro',
      padding: "15px 0 0 15px",
      "> div img": {
        display: "none"
      }
    }),
    input: (provided) => ({
      ...provided,
      position: "absolute",
      top: 0,
      height: "inherit",
      margin: 0,
      padding: "15px 0 0 15px",
      cursor: "pointer",
      width: "100%",
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: "markpro",
      backgroundColor: state.isFocused ? '#f5f5f6' : '',
      color: '#000000',
      "> div img": {
        visibility: state.isSelected ? 'visibile' : 'hidden'
      }
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: '#303030',
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)',

      ">svg": {
        height: '24px',
        width: '24px',
      },
      "&:hover": {
        color: '#303030'
      }
    })

  }

  useEffect(() => {
    setTimeout(() => {
      setFormData({ ...formData, load: true })
    }, 1500)

  }, [])

  const recaptchaExpired = () => {
    setFormData({
      ...formData,
      verifiedRecaptcha: false,
      expiredRecaptcha: true,
    });
  };

  const recaptchaLoaded = () => {
    console.log("Successful Load Recaptcha");
    setFormData({ ...formData, recaptchaLoaded: true });
  };

  const recaptchaVerifyResponse = (response) => {
    if (response) {
      setFormData({
        ...formData,
        verifiedRecaptcha: true,
        expiredRecaptcha: false,
      });
    } else {
      setFormData({ ...formData, verifiedRecaptcha: false });
    }
  };

  const validateField = (e, fields, fieldname, droplistValue) => {
    let { Name = null } = fields || {};
    let i = 0;
    formData.fields.map((items) => {
      if (items.fields.Type.value === "Checkbox Field") {
        if (items.fields.IsRequired.value) {
          if (items.fields.Name.value === Name.value) {
            items.fields.Value = e.target.checked;
            items.fields.Valid = e.target.checked;
          }
        } else if (items.fields.Name.value === Name.value) {
          items.fields.Value = e.target.checked;
        } else {
          items.fields.Valid = true;
        }
      } else if (items.fields.Type.value === "DropList") {
        if (items.fields.IsRequired.value) {
          if (items.fields.Name.value === fieldname) {
            items.fields.Value = droplistValue;
            items.fields.Valid = droplistValue ? true : false;
          }
        } else if (items.fields.Name.value === fieldname) {
          items.fields.Value = droplistValue;
        } else {
          items.fields.Valid = true;
        }
      } else if (items.fields.Name.value === Name.value) {
        items.fields.Value = e.target.value;
        items.fields.Touched = true;

        if (items.fields.Type.value === "Email Address Field") {
          formData.userEmailAddress = e.target.value;
        }

        if (items.fields.FormatValidation.value) {
          const fieldFormatValidation = props.fields.Fields[i].fields.FormatValidation.value;
          // let expression = items.fields.FormatValidation.value;
          let re = new RegExp(fieldFormatValidation);

          if (
            items.fields.IsRequired.value &&
            !re.test(String(e.target.value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else if (
            !items.fields.IsRequired.value &&
            e.target.value &&
            !re.test(String(e.target.value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else {
            items.fields.Valid = true;
          }
        } else {
          if (items.fields.IsRequired.value) {
            items.fields.Valid = e.target.value ? true : false;
          } else {
            items.fields.Valid = true;
          }
        }
      } else {
        if (items.fields.FormatValidation.value) {
          const fieldFormatValidation = props.fields.Fields[i].fields.FormatValidation.value;
          // let expression = items.fields.FormatValidation.value;
          let re = new RegExp(fieldFormatValidation);

          if (
            items.fields.IsRequired.value &&
            !re.test(String(items.fields.Value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else if (
            !items.fields.IsRequired.value &&
            items.fields.Value &&
            !re.test(String(items.fields.Value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else {
            items.fields.Valid = true;
          }
        } else {
          if (items.fields.IsRequired.value) {
            items.fields.Valid = items.fields.Value ? true : false;
          } else {
            items.fields.Valid = true;
          }
        }
      }

      setFormData({ ...formData, fields: formData.fields });
      i++;
    });

    checkFields();
  };

  const checkFields = () => {
    let data = formData.fields;
    for (let i = 0; i < data.length; i++) {
      if (data[i].fields.Valid !== true) {
        setFormData({ ...formData, complete: false });
        break;
      } else {
        setFormData({ ...formData, complete: true });
      }
    }
  };

  const handleSubmitBtn = (e) => {
    e.preventDefault();
    let contactUsWrapper = document.querySelector(
      ".contactus-wrapper"
    );
    let natureOfEnquiry = "";
    let formsWrapper = e.target,
      submitBtn = formsWrapper.querySelector(".submit-btn");

    const { verifiedRecaptcha } = formData;
    const recaptchaWrapper = document
      .getElementsByClassName("contactus-form")[0]
      .querySelector(".form-recaptcha");
    checkFields();
    setFormData({ ...formData, submitted: true });

    setTimeout(() => {
      let invalidField = formsWrapper.querySelector(".form-control-error, .react-select-container-error");
      //let invalidField = formsWrapper.querySelector(".form-control-error");
      if (invalidField) {
        let subNavWrapper = document.querySelector(".second-level-nagivation-container");
        if (subNavWrapper && window.outerWidth >= 992) {
          formsWrapper.scrollIntoView();
        } else {
          invalidField.scrollIntoView();
        }
      }
    }, 250);

    if (recaptchaWrapper) {
      if (!verifiedRecaptcha) {
        recaptchaWrapper.classList.add("is-required");
        return;
      }
    }
    if (!formData.complete) return;

    submitBtn.setAttribute("disabled", "");

    let fields = [];
    const dataSource = props.rendering.dataSource || "";
    const url = `/api/sitecore/form/enquireformsubmit`;

    // key compose field data
    formData.fields.map((field) => {
      if (field.fields.Type.value === "DropList") {
        fields.push({
          FieldName: field.fields.Name.value,
          Value: field.fields.Value.value ? `${field.fields.Value.label}|${field.fields.Value.value}` : field.fields.Value,
        });
        natureOfEnquiry = field.fields.Value ? field.fields.Value.label : ""; // for gtm
      } else if (field.fields.Type.value === "Checkbox Field") {
        fields.push({
          FieldName: field.fields.Name.value,
          Value: field.fields.Value ? field.fields.Value.toString() : "false",
        });
      } else {
        if (field.fields.Value) {
          fields.push({
            FieldName: field.fields.Name.value,
            Value: field.fields.Value,
          });
        }
      }
    });

    let postData = {
      EnquireFormPostObject: {
        ItemID: dataSource,
        UserEmailAddress: formData.userEmailAddress,
        Fields: fields
      },
    };

    //console.log('postData',postData)
    axios({
      url,
      method: "POST",
      data: postData,
    })
      .then((response) => {
        if (response && response.data && response.data.message) {
          console.log('successPage', successPageUrl);
          if (successPageUrl) {
            window.location.href = successPageUrl;
          } else {
            setFormData({
              ...formData,
              successMessage: response.data.message,
              submitted: false,
            });
          }

          TagManager.dataLayer({
            dataLayer: {
              event: "enquire_form_submit",
              natureOfEnquiry: natureOfEnquiry,
            },
            dataLayerName: "dataLayer",
          });
          contactUsWrapper.scrollIntoView();
        }
      })
      .catch((error) => {
        submitBtn.removeAttribute("disabled");
        console.error(error);
      });
  };

  const setVisible = (fields) => {
    if (fields && fields.length > 0) {
      return "show";
    }
    return "hide";
  };

  const renderFields = (fieldList) => {
    return fieldList.map((items, index) => {
      const {
        Type,
        Name,
        IsRequired,
        Touched,
        Valid,
        Value,
        Values,
        Placeholder,
        RequiredValidationMessage,
      } = items.fields;

      let fieldLayout = "";

      switch (Type.value) {
        case "Heading Field":
          fieldLayout = (
            <div className="contactus-subheading" key={"fieldId" + index}>
              <Text field={Name} />
            </div>
          );
          break;
        case "Text Field":
        case "Email Address Field":
        case "Phone Number field":
          fieldLayout = (
            <div className="form-group" key={"fieldId" + index}>
              <input
                type="text"
                ref={inputFieldRef}
                id={Name.value}
                required={IsRequired.value ? true : false}
                onChange={(e) => validateField(e, items.fields)}
                onBlur={(e) => validateField(e, items.fields)}
                className={`form-control form-control-lg ${(formData.submitted || Touched) &&
                  Valid !== true &&
                  (IsRequired.value || Valid === false)
                  ? "form-control-error"
                  : ""
                  }`}
                autoComplete="off"
              />
              <label
                className={`${IsRequired.value
                  ? "form-control-placeholder"
                  : "form-field-not-required"
                  } ${Value ? "form-field-hasValue" : ""}`}
                htmlFor={Name.value}
              >
                {Placeholder.value}
                {IsRequired.value && " *"}
              </label>
              {(formData.submitted || Touched) &&
                Valid !== true &&
                (IsRequired.value || Valid === false) ? (
                <span>
                  <i className="icon icon-danger contactus-danger-icon">!</i>
                  <label className="form-control-validation">
                    {RequiredValidationMessage.value}
                  </label>
                </span>
              ) : null}
            </div>
          );
          break;
        case "DropList":
          fieldLayout = (
            <div
              className="form-group"
              key={"fieldId" + index}
            >
              <Select
                className={`react-select-container ${(formData.submitted || Touched) && Valid !== true &&
                  (IsRequired.value || Valid === false)
                  ? "react-select-container-error"
                  : ""}`}
                classNamePrefix="react-select"
                options={Values.map(field => ({ value: field.fields.Id.value, label: field.fields.Name.value, icon: checkedOption }))}
                getOptionLabel={e => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img style={{ marginRight: '12px' }} src={e.icon} alt="" />
                    <span>{e.label}</span>
                  </div>
                )}
                placeholder={Placeholder.value}
                styles={customDropDownStyles}
                onChange={(e) => {
                  //console.log(e)
                  validateField(null, items.fields, Name.value, e);
                }}
              />
              <label
                className={`${IsRequired.value
                  ? "form-control-placeholder-dropdown"
                  : "form-field-not-required-dropdown"
                  } ${Value ? "form-field-hasValue" : ""}`}
                htmlFor={Name.value}
              >
                {Name.value}
                {IsRequired.value && " *"}
              </label>

              {(formData.submitted || Touched) &&
                Valid !== true &&
                (IsRequired.value || Valid === false) ? (
                <span>
                  <i className="icon icon-danger contactus-danger-icon">!</i>
                  <label className="form-control-validation">
                    {RequiredValidationMessage.value}
                  </label>
                </span>
              ) : null}
            </div>
          );
          break;
        case "Multi-Line Field":
          fieldLayout = (
            <div className="form-group form-textarea" key={"fieldId" + index}>
              <textarea
                id={Name.value}
                placeholder={Placeholder.value + (IsRequired.value ? " *" : "")}
                onChange={(e) => validateField(e, items.fields)}
                onBlur={(e) => validateField(e, items.fields)}
                className={
                  (formData.submitted || Touched) &&
                    Valid !== true &&
                    IsRequired.value
                    ? "form-control-error"
                    : "form-control"
                }
                autoComplete="off"
              />
              {(formData.submitted || Touched) &&
                Valid !== true &&
                IsRequired.value ? (
                <span>
                  <i className="icon icon-danger contactus-danger-icon">!</i>
                  <label className="form-control-validation">
                    {RequiredValidationMessage.value}
                  </label>
                </span>
              ) : null}
            </div>
          );
          break;
        case "Checkbox Field":
          fieldLayout = (
            <Checkbox
              key={"fieldId" + index}
              id={Name.value}
              name={Placeholder.value}
              handleCheckboxClick={(event) => {
                validateField(event, items.fields);
              }}
              isSubmitted={formData.submitted}
              isRequired={IsRequired.value}
              errorMessage={RequiredValidationMessage.value}
            />
          );
          break;
        default:
          fieldLayout = "";
      }

      return fieldLayout;
    });
  };


  let message = null;
  const { verifiedRecaptcha, expiredRecaptcha, load } = formData;
  const {
    EnableRecaptcha = null,
    PublicKey = null,
    PageSummary = null,
    Fields = null,
    PrivacyPolicy = null,
    UseAsModule = null
  } = props.fields || {};

  let { RemoveDefaultMargin = "" } = props.params || {};

  let margin =
    RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "" : "standard";

  if (formData.successMessage) {
    message = (
      <div
        className="thanks-for-contacting container"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formData.successMessage) }}
      />
    );
  }
  return (
    Fields && Fields.length > 0 &&
    <div className={`contactus-wrapper ${UseAsModule && UseAsModule.value ? "use-as-module" : ""}`}>
      {message ? (
        UseAsModule && UseAsModule.value ?
          <div className="container">
            {message}
          </div>
          : message
      ) : (
        <div className="container">
          <div className="contactus-description">
            <Text field={PageSummary} />
          </div>
          <form onSubmit={handleSubmitBtn} noValidate>
            <div className="contactus-form">
              <div className="row no-gutters">
                <div className="col col-md-9">
                  {Fields &&
                    Fields.length > 0 &&
                    renderFields(Fields)}
                  {load &&
                    EnableRecaptcha &&
                    EnableRecaptcha.value &&
                    PublicKey &&
                    PublicKey.value && (
                      <div
                        className={
                          "form-group form-recaptcha " +
                          (verifiedRecaptcha
                            ? "verifiedRecaptcha "
                            : " ") +
                          (expiredRecaptcha ? "is-required" : " ")
                        }
                      >
                        <ReCAPTCHA
                          size="normal"
                          sitekey={
                            PublicKey && PublicKey.value
                              ? PublicKey.value
                              : "6LeqAfYUAAAAAGK1oZ1NzfVUFeC4kqJOe16XklGg"
                          }
                          onChange={recaptchaVerifyResponse}
                          onExpired={recaptchaExpired}
                          asyncScriptOnLoad={recaptchaLoaded}
                        />
                        <div className="form-control-validation">
                          <span>
                            <label>ReCaptcha is required</label>
                          </span>
                        </div>
                      </div>
                    )}
                  {PrivacyPolicy && PrivacyPolicy.value && (
                    <div className="form-group form-text">
                      <RichText field={PrivacyPolicy} />
                    </div>
                  )}
                </div>
              </div>
              <div className="contactus-button">
                <button
                  className="btn btn-large btn--primary submit-btn"
                  type="submit"
                >
                  <span className="btn-label">{"Submit"}</span>
                  <i className="icon icon-chevron-up-2" />
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  )
};

export default FeatureDevMicrositeEnquireForm;
